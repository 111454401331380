import React, { useRef, useEffect, useContext, useState } from "react";
import { setupTooltips } from "../../utils";
import useTranslations from "../../hooks/useTranslations";
import { ModalContext } from "../../context/ModalContext";
import SearchableSelect from "../global/SearchableSelect";
import MessagesService from "../../services/MessagesService";
import { AvatarsContext } from "../../context/AvatarsContext";
import { MessagesContext } from "../../context/MessagesContext";
import { TemplatesContext } from "../../context/TemplatesContext";
import { ConversationsContext } from "../../context/ConversationsContext";
import { OrganizationsContext } from "../../context/OrganizationsContext";
import ConversationFilesModal from "../conversations/ConversationFilesModal";
import { modelLabels, models } from "../../utils/models";

const MessageInputForm = ({
  spinner,
  handleSubmit,
  addGenerateBtn,
  handleGenerateBtn,
  disableTemplatesBtn,
}) => {
  const textArea = useRef(null);

  const [model, setModel] = useState({
    value: "gpt-4o-mini",
    name: "GPT 4o Mini",
  });
  const [tokens, setTokens] = useState(0);

  const translations = useTranslations();

  const { setTemplate } = useContext(TemplatesContext);
  const { clearModal } = useContext(ModalContext);
  const { conversation, setPropertyConversation } =
    useContext(ConversationsContext);

  const {
    prompt,
    enhanced,
    enhancing,
    setPrompt,
    setEnhanced,
    setEnhancing,
    maxConversationTokens,
  } = useContext(MessagesContext);

  const { organization } = useContext(OrganizationsContext);
  const { modalComponent } = useContext(ModalContext);

  const {
    avatars,
    setConversationAvatar,
    getAvatars,
    clearAvatars,
    conversationAvatar,
  } = useContext(AvatarsContext);

  const fetchAvatars = (query) => {
    clearAvatars();
    getAvatars(query);
  };

  useEffect(() => {
    setupTooltips();
    fetchAvatars();
    if (prompt && prompt !== null) {
      setTemplate(null);
    }
    return () => {
      setPrompt("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const setupHeight = () => {
      if (prompt === "") {
        textArea.current.style.height = "0px";
      } else {
        textArea.current.style.height = textArea.current.scrollHeight + "px";
      }
    };

    setupHeight();
  }, [textArea, prompt]);

  useEffect(() => {
    setTokens(Math.round(prompt.length / 4));
  }, [prompt]);

  useEffect(() => {
    if (enhanced && enhanced !== null) {
      setEnhancing(false);
      setPrompt(enhanced);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enhanced]);

  const handleSelectAvatar = (option) => {
    setConversationAvatar(option.value);
    clearModal();
  };

  const conversationsFilesModal = () => {
    return modalComponent(
      translations.conversations.conversation_files,
      <ConversationFilesModal />
    );
  };

  const handleSelectModel = () => {
    modalComponent(
      "Select Model",
      <div>
        <SearchableSelect
          items={models}
          value={model}
          itemText="name"
          modifier={setModel}
          placeholderText="Model"
        />
        <button onClick={clearModal} className="btn mt-3 w-100 btn-primary">
          Save
        </button>
      </div>
    );
  };

  const avatarSelectModal = () => {
    if (avatars && Array.isArray(avatars)) {
      const options = [...avatars].map((avatar) => {
        avatar.value = avatar;
        return avatar;
      });

      return modalComponent(
        "Select an Avatar",
        <div>
          <SearchableSelect
            items={options}
            itemText="name"
            placeholderText={`Select an avatar`}
            modifier={(option) => handleSelectAvatar(option)}
            value={conversationAvatar}
          />
          {conversationAvatar && (
            <button
              className="mt-3 btn btn-sm btn-outline-primary w-100"
              onClick={() => {
                handleSelectAvatar({ value: null });
              }}
            >
              Remove Avatar
            </button>
          )}
        </div>,
        { conversationAvatar }
      );
    }
    return null;
  };

  const handleEnhance = () => {
    setEnhancing(true);

    const messageData = {
      content: `Transform this lackluster prompt into a more effective and engaging question or statement: "${prompt}". Write the instruction to get the result, not the result itself. Respond always in the message language.`,
      enhance: true,
      stream: false,
    };

    if (conversation.model && conversation.model !== null) {
      messageData.model = conversation.model;
    } else {
      messageData.model = model.value || null;
    }

    if (
      organization.organization_id &&
      organization.organization_id !== undefined
    ) {
      messageData.organization_id = organization.organization_id;
    }

    MessagesService.postMessage(messageData);
  };

  const handleTemplates = () => {
    if (conversation?.showTemplates) {
      setPropertyConversation("showTemplates", false);
    } else {
      setPropertyConversation("showTemplates", true);
    }
  };

  return (
    <div id="message-input" className="card p-3 pb-0 w-100">
      <form
        className="row"
        onSubmit={(e) => {
          e.preventDefault();
          setEnhancing(false);
          handleSubmit({
            prompt,
            model: conversation?.model || model?.value || null,
          });
          setEnhanced(null);
          setPrompt("");
        }}
      >
        <div className="px-0 mb-3">
          <button
            type="button"
            onClick={handleSelectModel}
            disabled={conversation?.model !== null}
            className="btn me-2 btn-sm btn-outline-primary"
            style={{
              padding: "6px 12px",
            }}
          >
            <i className="fas fa-brain me-1" />
            {modelLabels[conversation?.model] ||
              model?.name ||
              model?.label ||
              "Model"}
          </button>

          <button
            type="button"
            disabled={enhancing || disableTemplatesBtn}
            onClick={handleTemplates}
            className={`${
              disableTemplatesBtn ? "d-none" : ""
            } btn btn-sm btn-outline-primary me-2`}
            style={{
              padding: "6px 12px",
            }}
          >
            <i className="fas fa-shapes me-2"></i>
            {translations.conversation.input.templates.button}
          </button>

          {model?.value !== "grok" && (
            <button
              type="button"
              onClick={() => conversationsFilesModal()}
              className="btn btn-sm btn-outline-primary me-2"
              style={{
                padding: "6px 12px",
              }}
            >
              <i className="fas fa-file-alt me-2" />
              {translations.conversations.files}
            </button>
          )}

          <button
            type="button"
            onClick={avatarSelectModal}
            className="btn me-2 btn-sm btn-outline-primary"
            style={{
              padding: "6px 12px",
            }}
          >
            {conversationAvatar ? (
              conversationAvatar.name
            ) : (
              <>
                <i className="fa fa-user me-1" /> Avatar
              </>
            )}
          </button>
        </div>
        <div className="mb-2 px-0">
          <textarea
            type="text"
            ref={textArea}
            value={prompt}
            className="form-control"
            placeholder={translations.conversation.input.placeholder}
            onChange={(e) => setPrompt(e.target.value)}
          />
        </div>
        <div className="row px-0">
          <div className="col-12 col-md-6 ps-0">
            {String(prompt).length > 12 && (
              <button
                type="button"
                onClick={handleEnhance}
                disabled={enhancing || tokens > maxConversationTokens}
                className="btn btn-sm btn-outline-primary me-2"
              >
                {enhancing ? (
                  <div className="spinner-border small"></div>
                ) : (
                  <span>
                    <i className="fas fa-magic me-2"></i>
                    {translations.conversation.input.enhance}
                  </span>
                )}
              </button>
            )}

            <span
              className="ms-auto me-2 text-gradient"
              style={{ fontSize: "14px" }}
            >
              Tokens: {tokens} / {maxConversationTokens}
            </span>

            <button
              type="button"
              onClick={handleGenerateBtn}
              className={`${addGenerateBtn ? "" : "d-none"} btn btn-sm
              btn-accent me-2`}
            >
              <i className="fa fa-arrow-left me-2"></i>
              Back
            </button>
          </div>
          <div className="col-12 col-md-6 px-0">
            <button
              type="submit"
              disabled={
                spinner ||
                enhancing ||
                String(prompt).length < 1 ||
                tokens > maxConversationTokens
              }
              className="btn btn-primary w-100 mt-2"
            >
              {spinner ? (
                <div className="spinner-border"></div>
              ) : (
                <span>
                  <i className="fa fa-paper-plane me-2"></i>
                  {translations.conversation.input.send}
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MessageInputForm;
