import React, { useContext, useEffect } from "react";
import DynamicForm from "../global/DynamicForm";
import useTranslations from "../../hooks/useTranslations";
import { ConversationsContext } from "../../context/ConversationsContext";
import { OrganizationsContext } from "../../context/OrganizationsContext";

const ConversationForm = ({ handleCancel }) => {
  const translations = useTranslations();
  const { spinner, conversation, saveConversation, setPropertyConversation } =
    useContext(ConversationsContext);
  const { organization } = useContext(OrganizationsContext);

  useEffect(() => {
    if (organization && organization.organization_id != null) {
      setPropertyConversation("organization_id", organization.organization_id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const questions = [
    {
      id: "name",
      type: "text",
      required: true,
      label: translations.conversation.form.name,
    },
    {
      id: "tags",
      type: "tags",
      required: false,
    },
    {
      id: "model",
      label: "Model",
      type: "select",
      required: false,
      options: [
        { value: null, label: "Unset" },
        { value: "gpt-4o-mini", label: "GPT 4o Mini" },
        { value: "grok-2-latest", label: "Grok" },
        { value: "claude-3-7-sonnet-20250219", label: "Claude" },
      ],
    },
  ];

  return (
    <div>
      <DynamicForm
        spinner={spinner}
        object={conversation}
        questions={questions}
        handleCancel={handleCancel}
        saveAction={saveConversation}
        modifier={setPropertyConversation}
      />
    </div>
  );
};

export default ConversationForm;
