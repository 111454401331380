export const models = [
  { value: "gpt-4o-mini", name: "GPT 4o Mini" },
  { value: "grok-2-latest", name: "Grok" },
  { value: "claude-3-7-sonnet-20250219", name: "Claude" },
];

export const modelLabels = {
  "gpt-4o-mini": "GPT 4o Mini",
  "grok-2-latest": "Grok",
  "claude-3-7-sonnet-20250219": "Claude",
};
